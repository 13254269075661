import * as React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import { rhythm } from '../utils/typography'
import {WindowLocation} from "@reach/router";
interface BlogIndexProps {
    data: {
        site: {
            siteMetadata: {
                name: string;
                title: string;
                description: string;
                author: string;
            }
        };
        allMarkdownRemark: any;
    };
    location: WindowLocation;
}

class BlogIndex extends React.Component<BlogIndexProps> {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges;

    return (
      <Layout location={this.props.location} title={this.props.data.site.siteMetadata.title}>
        <SEO
            description=""
            title="dadbox.eu documentation"
            keywords={[`dadbox`, `dadbox.eu`, `genealogie`, `genealogy`, `Ahnenforschung`]}
        />
        {posts.map(({ node }: any) => {
          const title = node.frontmatter.title || node.fields.slug;
          return (
            <div key={node.fields.slug}>
              <h3
                style={{
                  marginBottom: rhythm(1 / 4),
                }}
              >
                <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                  {title}
                </Link>
              </h3>
              <small>{node.frontmatter.date}</small>
              <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
            </div>
          )
        })}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`;
